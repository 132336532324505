<template>
  <v-img
    v-if="imgResp.src"
    :aspect-ratio="aspectRatio"
    :sizes="imgResp.sizes"
    :src="imgResp.src"
    :srcset="imgResp.srcset"
    alt="Cover image for resource"
    cover
  >
    <slot />
  </v-img>
</template>

<script setup>
import { toRef } from 'vue'
import { usePageContext } from '#root/renderer/usePageContext'
import { useImgResponsive } from '#root/lib/img'

const props = defineProps({
  aspectRatio: {
    default: 1,
    type: Number
  },
  defaultKey: {
    default: undefined,
    type: String
  },
  imageKey: {
    default: 'cover_image',
    type: String
  },
  value: {
    required: true,
    type: Object
  }
})

const { siteConfig } = usePageContext()
const imgResp = useImgResponsive(
  toRef(() => ({
    aspectRatio: props.aspectRatio,
    image: props.value[props.imageKey]
      ? props.value[props.imageKey]
      : props.defaultKey && siteConfig[props.defaultKey]
        ? siteConfig[props.defaultKey]
        : undefined
  }))
)
</script>
